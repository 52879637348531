#header {
  .full-header {
    display: none;
    grid-template-columns: 165px 1fr 165px;
    align-items: center;
    height: 140px; /* Example height, adjust as needed */
    width: 100%; /* Full width of the parent container */
    padding: 38px 48px;

    .sides {
      width: 165px;
      height: 63px;
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .give-button {
        background-color: var( --e-global-color-accent );
        color: white;
        font-size: 36px;
        padding: 12px 36px;
        font-weight: 500;
        line-height: 1;
      }
    }

    .middle {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .first-level-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1vw;

        & > a {
          color: var(--e-global-color-text);
          font-size: clamp(0.4rem, 1.2vw, 1.8rem);
          font-weight: 600;
          text-align: center;

          &:hover, &.active {
            color: var(--e-global-color-accent);
          }
        }

        .children {
          display: none;
        }
        &:hover .children {
          display: flex;
          flex-direction: column;
          position: absolute;
          background-color: white;
          border: 1px solid var(--e-global-color-secondary);
          padding: 12px;
          gap: 12px;
          z-index: 1001;
          margin-top: 15px;
          top: 2vw;
          left: 1vw;

          a {
            color: var(--e-global-color-text);
            font-size: 1.4rem;
            font-size: clamp(0.4rem, 1vw, 1.8rem);

            &:hover, &.active {
              color: var(--e-global-color-accent);
            }
          }
        }
      }
      a {
        line-height: 1;
      }
    }
  }

  .mobile-header {
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    align-items: center;
    height: 100px; /* Example height, adjust as needed */
    width: 100%; /* Full width of the parent container */
    gap: 10px; /* Optional gap between columns */

    .square {
      aspect-ratio: 1 / 1; /* Make the element square */
    }

    .middle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      padding: 10px 0;

      a {
        line-height: 0;
      }
      img {
        max-height: 80px;
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      svg {
        height: 35px;
        display: block !important;
        fill: var(--e-global-color-text);
      }
    }
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1000;

    &.active {
      display: flex;
      flex-direction: column;
    }

    .menu-level {
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: none;
      &.active {
        display: flex;
      }
    }

    .first-level-item {
      color: var(--e-global-color-text);
      font-size: 2rem;
      padding: 0 0 36px 0;
      line-height: 1;
    }

    .second-level-item {
      color: var(--e-global-color-text);
      font-size: 1.4rem;
      padding: 0 0 32px 0;
      line-height: 1;
      text-align: center;
      max-width: 65vw;
    }

    .menu-back-button {
      color: var(--e-global-color-text);
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0 0 32px 0;
      line-height: 1;
    }
  }

  @media (min-width: 960px) {
    .mobile-header {
      display: none;
    }
    .full-header {
      display: grid;
    }
  }
}

